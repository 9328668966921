
svg {
  position: absolute;
  top:0;
  left:0;
  font-size: 72px;
  font-weight: 800;
  z-index: 3;
}

svg rect {
  fill: white;
  mask: url("#mask");
}

svg > rect {
  fill: black;
}